import { defineStore } from 'pinia'
import { nextTick, reactive, ref } from 'vue'
import { cloneDeep } from 'lodash'

export const useTradeInStore = defineStore('tradeInStore', {
  state: () => {
    return {
      clientId: null,
      initialState: null,
      loading: false,
      showTradeInWidget: false,
      currentStepKey: null,
      currentExpandedKey: null,
      previousStepKey: null,
      previousAccordionKey: null,
      nextAccordionKey: null,
      nextStepKey: null,
      nextStep: null,
      error: null,
      vehicleSelectionError: null,
      hsnTsnError: null,
      finError: null,
      inputError: null,
      colors: {},
      allStepsSelected: Boolean,
      nextStepSelexted: false,
      activeTab: 'hersteller',
      tradeInData: {
        count: [],
        equipmentMatrix: [],
        filters: [],
        settings: {},
        vehicles: []
      },
      showVin: null,
      showSelection: null,
      showKba: null,
      isEmailRequired: null,
      isPhoneRequired: null,
      selectedVehicle: ref([]),
      brandMappings: {
        504: 'lynk-&-co',
        70: 'rover',
        730: 'rover',
        235: 'ds-automobiles',
        905: 'vw',
        40: 'alfa-romeo',
        311: 'ora'
        // add more mappings when needed
      },
      months: {
        '01': 'Januar',
        '02': 'Februar',
        '03': 'März',
        '04': 'April',
        '05': 'Mai',
        '06': 'Juni',
        '07': 'Juli',
        '08': 'August',
        '09': 'September',
        10: 'Oktober',
        11: 'November',
        12: 'Dezember'
      },
      vehicles: reactive({}),
      setting: reactive({}),
      // vehicleSelection Accordion
      accordion: reactive({
        accordion1: {
          title: 'Hersteller, Modell',
          content: 'manufacturer',
          steps: {
            manufacturer: {
              title: 'Hersteller',
              proposals: [],
              selectedProposal: [],
              selected: false
            },
            mainTypeGroupFb: {
              title: 'Modell',
              proposals: [],
              selectedProposal: [],
              selected: false
            },
            structureType: {
              title: 'Fahrzeugtyp',
              proposals: [],
              selectedProposal: [],
              selected: false
            },
            fuelMethod: {
              title: 'Kraftstoff',
              proposals: [],
              selectedProposal: [],
              selected: false
            }
            // Example for implement new steps
            // countOfDoors: {
            //   title: 'Anzahl der Türen',
            //   proposals: [],
            //   selectedProposal: [],
            //   selected: false
            // }
          }
        },
        accordion2: {
          title: 'Fahrzeugdaten',
          content: 'vehicleDetail',
          steps: {
            constructionYear: {
              title: 'Baujahr / Erstzulassung',
              proposals: [],
              selectedProposal: [],
              selected: false
            },
            engine: {
              title: 'Motorisierung',
              proposals: [],
              selectedProposal: [],
              selected: false
            },
            subType: {
              title: 'Austattungsvariante',
              proposals: [],
              selectedProposal: [],
              selected: false
            },
            gearbox: { title: 'Getriebe', proposals: [], selectedProposal: [], selected: false }
          }
        },
        accordion3: {
          title: 'Sonderausstattung',
          content: 'equipment',
          steps: {
            specialEquipments: {
              title: 'Sonderaustattung',
              proposals: {},
              selectedProposal: {},
              selected: false,
              visited: false
            }
          }
        },
        accordion4: {
          title: 'Erstzulassung, Kilometerstand',
          content: 'carRegistration',
          steps: {
            carRegistration: {
              selectedProposal: {},
              selected: false,
              visited: false
            }
          }
        },
        accordion5: {
          title: 'Fahrzeugzustand',
          content: 'damage',
          steps: {
            accident: {
              selectedProposal: { accident: false, accidentDescription: '' },
              selected: false
            },
            damage: { selectedProposal: { damage: false, damageDescription: '' }, selected: false }
          }
        },
        accordion6: {
          title: 'Preis',
          content: 'priceRequest',
          steps: {
            price: { selectedProposal: { mwSt: false }, selected: false },
            finance: { selectedProposal: { financing: false }, selected: false }
          }
        },
        accordion7: {
          title: 'Kostenlos & unverbindlich anfragen',
          content: 'submit',
          steps: {
            submit: {
              selectedProposal: { firstName: '', lastName: '', email: '', privacyPolicy: false },
              selected: false,
              visited: false
            }
          }
        }
      }),
      // HSN/TSN Accordion
      hsnTsnaccordion: reactive({
        accordion1: {
          title: 'HSN/TSN',
          content: 'hsnTsn',
          steps: {
            hsnTsn: {
              selectedProposal: {},
              selected: false,
              visited: false
            }
          }
        },
        accordion2: {
          title: 'Sonderausstattung',
          content: 'equipment',
          steps: {
            specialEquipments: {
              title: 'Sonderaustattung',
              proposals: {},
              selectedProposal: {},
              filteredProposals: {},
              selected: false,
              visited: false
            }
          }
        },
        accordion3: {
          title: 'Erstzulassung, Kilometerstand',
          content: 'carRegistration',
          steps: {
            carRegistration: {
              selectedProposal: {},
              selected: false,
              visited: false
            }
          }
        },
        accordion4: {
          title: 'Fahrzeugzustand',
          content: 'damage',
          steps: {
            accident: {
              selectedProposal: { accident: false, accidentDescription: '' },
              selected: false
            },
            damage: { selectedProposal: { damage: false, damageDescription: '' }, selected: false }
          }
        },
        accordion5: {
          title: 'Preis',
          content: 'priceRequest',
          steps: {
            price: { selectedProposal: { mwst: false }, selected: false },
            finance: { selectedProposal: { financing: false }, selected: false }
          }
        },
        accordion6: {
          title: 'Kostenlos & unverbindlich anfragen',
          content: 'submit',
          steps: {
            submit: {
              selectedProposal: { firstName: '', lastName: '', email: '', privacyPolicy: false },
              selected: false,
              visited: false
            }
          }
        }
      }),
      // VIN Accordion
      vinAccordion: reactive({
        accordion1: {
          title: 'FIN',
          content: 'vin',
          steps: {
            vin: {
              selectedProposal: {},
              selected: false,
              visited: false
            }
          }
        },
        accordion2: {
          title: 'Sonderausstattung',
          content: 'equipment',
          steps: {
            specialEquipments: {
              title: 'Sonderaustattung',
              proposals: {},
              selectedProposal: {},
              selected: false,
              visited: false
            }
          }
        },
        accordion3: {
          title: 'Erstzulassung, Kilometerstand',
          content: 'carRegistration',
          steps: {
            carRegistration: {
              selectedProposal: {},
              selected: false,
              visited: false
            }
          }
        },
        accordion4: {
          title: 'Fahrzeugzustand',
          content: 'damage',
          steps: {
            accident: {
              selectedProposal: { accident: false, accidentDescription: '' },
              selected: false
            },
            damage: { selectedProposal: { damage: false, damageDescription: '' }, selected: false }
          }
        },
        accordion5: {
          title: 'Preis',
          content: 'priceRequest',
          steps: {
            price: { selectedProposal: { mwst: false }, selected: false },
            finance: { selectedProposal: { financing: false }, selected: false }
          }
        },
        accordion6: {
          title: 'Kostenlos & unverbindlich anfragen',
          content: 'submit',
          steps: {
            submit: {
              selectedProposal: { firstName: '', lastName: '', email: '', privacyPolicy: false },
              selected: false,
              visited: false
            }
          }
        }
      }),
      priority: ['905', '800', '60', '650', '130', '190', '570', '720', '285', '280', '670', '225']
    }
  },
  actions: {
    openTradeInWidget() {
      this.showTradeInWidget = true
    },
    closeTradeInWidget() {
      this.resetTradeInStore()
      this.showTradeInWidget = false
    },
    resetTradeInStore() {
      Object.assign(this, this.initialState) // reset to initial state
      this.initialState = cloneDeep(this)
    },
    setAttributesFromProps(attrs) {
      if (!attrs.clientId || isNaN(parseInt(attrs.clientId))) {
        this.error = {
          message: 'Problem with the value at json.clientId:\n \nnull\n',
          detail: '\nExpecting an INT'
        }
        return
      }
      this.clientId = attrs.clientId
      Object.keys(this.accordion).forEach((accordionKey) => {
        Object.keys(this.accordion[accordionKey].steps).forEach((stepKey) => {
          const attrName = stepKey.toLowerCase()
          if (attrs[attrName] && stepKey === 'manufacturer') {
            this.accordion[accordionKey].steps[stepKey].selectedProposal.k = attrs[attrName]
            this.accordion[accordionKey].steps[stepKey].selected = true
          } else if (attrs[attrName]) {
            this.accordion[accordionKey].steps[stepKey].selectedProposal.k = attrs[attrName]
          }
        })
      })
    },

    async fetchTradeInData() {
      // console.log('fetchTradeInData called')

      this.hsnTsnError = null
      this.loading = true
      if (!this.clientId) return
      try {
        const url = this.buildUrlWithQueryParams()
        const response = await fetch(url)
        if (!response.ok) {
          const error = new Error(`Error ${response.status}`)
          error.statusCode = response.status
          error.url = url.href
          throw error
        }
        const data = await response.json()
        this.processResponseData(data)
      } catch (error) {
        // console.log('Status Code:', error.statusCode)
        // console.log('URL:', error.url)
        // console.log('Status Code:', error.statusCode)
        // console.log('URL:', error.url)
        const urlParams = new URLSearchParams(error.url.split('?')[1])

        switch (error.statusCode) {
          case 404:
            if (urlParams.has('kba')) {
              this.hsnTsnError =
                'Kein Fahrzeug zu dieser HSN/TSN gefunden, Bitte versuchen Sie es mit der Hersteller/Modell auswahl!'
            } else if (urlParams.has('vin')) {
              this.vinError = 'Kein Fahrzeug zu dieser FIN gefunden!'
            } else {
              this.inputError = 'Die angeforderte Ressource wurde nicht gefunden.'
            }
            break
          case 500:
            if (urlParams.has('kba')) {
              this.hsnTsnError = 'HSN/TSN Falsch! Bitte überprüfen Sie Ihre Eingabe.'
            } else if (urlParams.has('vin')) {
              this.vinError = 'FIN Falsch! Bitte überprüfen Sie Ihre Eingabe.'
            } else {
              this.inputError = 'Die angeforderte Ressource wurde nicht gefunden.'
            }
            break

          default:
            this.inputError =
              'Interner Server Fehler, bitte wende dich an den Support oder an den Website-Betreiber'
        }
      } finally {
        this.loading = false
      }
    },

    processResponseData(data) {
      this.vehicleSelectionError = null
      try {
        if (data.count === 0 && data.vehicles.length === 0) {
          this.vehicleSelectionError = 'Keine Fahrzeuge passend zur aktuellen Auswahl gefunden!'
          return
        }
        this.tradeInData = data
        this.vehicles = this.tradeInData.vehicles
        this.setting = this.tradeInData.settings
        this.colors = this.setting.colors
        this.showKba = this.setting.showKba
        this.showSelection = this.setting.showSelection
        this.showVin = this.setting.showVin
        this.isEmailRequired = this.setting.isEmailRequired
        this.isPhoneRequired = this.setting.isPhoneRequired

        const stepKey = this.defineStepKey()

        if (stepKey) {
          // Store proposals in the next step
          if (stepKey === 'specialEquipments') {
            this.nextStep.steps[stepKey].proposals = this.tradeInData.equipmentMatrix

            // console.log(
            //   'equipmentMatrix',
            //   JSON.stringify(this.tradeInData.equipmentMatrix, null, 2)
            // )
          } else {
            // Store the proposals data in tradeInData.filters
            const proposals = this.tradeInData.filters.find(
              (filter) => filter.key === stepKey
            ).proposals

            // Sort proposals alphabetically by name
            proposals.sort((a, b) => {
              const aValue = a.v
              const bValue = b.v

              // Check if both values are numbers
              if (!isNaN(aValue) && !isNaN(bValue)) {
                return aValue - bValue
              }

              // Check if one value is a number and the other is not
              if (!isNaN(aValue)) {
                return -1
              }
              if (!isNaN(bValue)) {
                return 1
              }

              // Check if both values are numbers with letters
              const aHasNumber = aValue.match(/\d/)
              const bHasNumber = bValue.match(/\d/)
              if (aHasNumber && bHasNumber) {
                return aValue.localeCompare(bValue)
              }

              // Check if one value has a number and the other does not
              if (aHasNumber) {
                return -1
              }
              if (bHasNumber) {
                return 1
              }

              // Both values are only letters, sort alphabetically
              return aValue.localeCompare(bValue)
            })
            // console.log('sortedProposals', JSON.stringify(proposals, null, 2))
            this.nextStep.steps[stepKey].proposals = proposals
          }
          // If there's only one proposal, select it automatically
          if (this.nextStep.steps[stepKey].proposals.length === 1) {
            const accordionKey = Object.keys(this.accordion).find(
              (accordionKey) => this.accordion[accordionKey] === this.nextStep
            )

            this.nextStep.steps[stepKey].selected = true

            this.currentExpandedKey = accordionKey
            this.previousStepKey = stepKey
            this.previousAccordionKey = accordionKey
            this.currentStepKey = stepKey

            this.processResponseData(data) // continue processing
          }
        }
        // If manufactuer.proposals sort dem VW, SKODA, AUDI, OPEL, BMW, ....
        if (this.accordion['accordion1'].steps['manufacturer'].proposals) {
          this.accordion['accordion1'].steps['manufacturer'].proposals.sort((a, b) => {
            const priorityA = this.priority.indexOf(a.k)
            const priorityB = this.priority.indexOf(b.k)
            if (priorityA === -1) return 1
            if (priorityB === -1) return -1
            return priorityA - priorityB
          })

          // ...
        }
      } catch (error) {}
    },
    filterEquipmentProposals() {
      const accordion = this.getDisplayedAccordion()[this.currentExpandedKey]
      const step = accordion.steps[this.currentStepKey].selectedProposal
      const equipmentMatrix = this.tradeInData.equipmentMatrix
      const selectedVehicleEcode = this.selectedVehicle.ecode
      const selectedVehicleEquipment = this.selectedVehicle.selectedEquipment

      const filteredProposals = equipmentMatrix.filter((proposal) => {
        const available = proposal.available
        return available[selectedVehicleEcode] === 'X'
      })

      return filteredProposals
    },

    buildUrlWithQueryParams() {
      const url = new URL('https://tradein.cloud.audaris.icu/-/api')
      url.searchParams.set('clientId', this.clientId)

      if (this.activeTab === 'hsnTsn' || this.activeTab === 'vin') {
        const accordion = this.getDisplayedAccordion()
        const step = accordion.accordion1.steps[this.activeTab]
        if (step.selectedProposal) {
          const key = this.activeTab === 'hsnTsn' ? 'kba' : 'vin'
          const value =
            this.activeTab === 'hsnTsn'
              ? `${step.selectedProposal.hsn}/${step.selectedProposal.tsn}`
              : step.selectedProposal.vin
          url.searchParams.set(key, value)
        }
      } else {
        Object.values(this.accordion).forEach((accordion) => {
          Object.keys(accordion.steps).forEach((stepName) => {
            const step = accordion.steps[stepName]
            if (step.selectedProposal && step.selectedProposal.k) {
              url.searchParams.set(stepName, step.selectedProposal.k)
            }
          })
        })
      }

      return url
    },
    getDisplayedAccordion() {
      return this.activeTab === 'hersteller'
        ? this.accordion
        : this.activeTab === 'vin'
          ? this.vinAccordion
          : this.hsnTsnaccordion
    },
    toggleAccordion(index) {
      // console.log(`toggleAccordion called with index: ${index}`)
      const accordion = this.getDisplayedAccordion()[index]
      this.allStepsSelected = Object.values(accordion.steps).every((step) => step.selected)
      const currentStepInAccordion = this.defineStepKey()

      // console.log(`allStepsSelected: ${this.allStepsSelected}`)
      // console.log(`currentStepInAccordion: ${currentStepInAccordion}`)

      if (this.allStepsSelected || currentStepInAccordion) {
        // console.log(
        //   `setting previousAccordionKey form toggle Accordion to ${this.currentExpandedKey}`
        // )

        // console.log(`setting currentExpandedKey form toggle Accordion to ${index}`)
        this.currentExpandedKey = index

        // Open the last step with more than one proposal or the last step in the accordion
        const lastStepWithProposals = Object.values(accordion.steps)
          .slice()
          .reverse()
          .find(
            (step) =>
              step !== null && step !== undefined && step.proposals && step.proposals.length > 1
          )

        if (!lastStepWithProposals) {
          // Find the first step with selected set to false
          const firstUnselectedStep = Object.values(accordion.steps).find(
            (step) => step.selected === false
          )

          if (firstUnselectedStep) {
            this.currentStepKey = Object.keys(accordion.steps).find(
              (key) => accordion.steps[key] === firstUnselectedStep
            )
          } else {
            // If no unselected steps, set currentStepKey to the first step
            this.currentStepKey = Object.keys(accordion.steps)[0]
          }
        } else {
          this.currentStepKey = Object.keys(accordion.steps).find(
            (key) => accordion.steps[key] === lastStepWithProposals
          )
        }
        // console.log(`currentStepKey form toggle Accordion: ${this.currentStepKey}`)

        // Update previousStepKey and previousAccordionKey
        this.previousStepKey = this.currentStepKey
        this.previousAccordionKey = this.currentExpandedKey

        // Update nextAccordionKey and nextStepKey
        const accordionKeys = Object.keys(this.getDisplayedAccordion())
        const currentAccordionIndex = accordionKeys.indexOf(this.currentExpandedKey)
        if (currentAccordionIndex < accordionKeys.length - 1) {
          this.nextAccordionKey = accordionKeys[currentAccordionIndex + 1]
          this.nextStepKey = Object.keys(
            this.getDisplayedAccordion()[this.nextAccordionKey].steps
          )[0]
        } else {
          this.nextAccordionKey = null
          this.nextStepKey = null
        }
      } else {
        // console.log(`not all steps selected, toggling to last selected step`)
        if (this.previousAccordionKey !== null) {
          // console.log(`toggling back to previous accordion index: ${this.previousAccordionKey}`)
          this.currentExpandedKey = this.previousAccordionKey
          this.previousAccordionKey = null
        } else {
          const currentStepKey = this.defineStepKey()
          // console.log(`currentStepKey form toggle Accordion: ${currentStepKey}`)
          if (currentStepKey) {
            // console.log(`finding expanded index for current step key`)
            this.currentExpandedKey = this.findExpandedIndex(currentStepKey)
          }
        }
      }
      // console.log(`final currentExpandedKey: ${this.currentExpandedKey}`)
      // console.log(`final previousAccordionKey: ${this.previousAccordionKey}`)
    },

    async goForward() {
      const displayedAccordion = this.getDisplayedAccordion()
      if (this.nextAccordionKey !== null && this.nextStepKey !== null) {
        if (
          displayedAccordion[this.nextAccordionKey].steps[this.nextStepKey].proposals?.length === 1
        ) {
          // console.log(
          //   'Aktueller Schritt hat nur ein Proposal, suche nach nächstem Schritt mit mehr als einem Proposal...'
          // )
          const nextSteps = Object.keys(displayedAccordion[this.currentExpandedKey].steps)
          const currentIndex = nextSteps.indexOf(this.currentStepKey)
          let nextStepKey = null
          for (let i = currentIndex + 1; i < nextSteps.length; i++) {
            // console.log('Überprüfe Schritt:', nextSteps[i])
            if (
              displayedAccordion[this.currentExpandedKey].steps[nextSteps[i]].proposals.length > 1
            ) {
              // console.log('Nächster Schritt mit mehr als einem Proposal gefunden:', nextSteps[i])
              nextStepKey = nextSteps[i]
              break
            }
          }
          if (nextStepKey === null) {
            // console.log(
            //   'Kein nächster Schritt mit mehr als einem Proposal gefunden, gehe zum nächsten Akkordeon...'
            // )
            const accordionKeys = Object.keys(displayedAccordion)
            const currentAccordionIndex = accordionKeys.indexOf(this.currentExpandedKey)
            const nextAccordionKey = accordionKeys[currentAccordionIndex + 1]
            this.nextAccordionKey = nextAccordionKey
            this.nextStepKey = Object.keys(displayedAccordion[nextAccordionKey].steps)[0]
          } else {
            this.nextStepKey = nextStepKey
          }
        }
        this.previousAccordionKey = this.currentExpandedKey
        this.previousStepKey = this.currentStepKey

        this.currentExpandedKey = this.nextAccordionKey
        this.currentStepKey = this.nextStepKey
        await nextTick()

        // Find the Index of the current step
        const currentStepIndex = Object.keys(
          displayedAccordion[this.currentExpandedKey].steps
        ).indexOf(this.currentStepKey)

        // Find the Index of the current accordion
        const accordionKeys = Object.keys(displayedAccordion)
        const currentAccordionIndex = accordionKeys.indexOf(this.currentExpandedKey)

        // if current step is not the last step, update nextAccordionKey and nextStepKey
        if (
          currentStepIndex <
          Object.keys(displayedAccordion[this.currentExpandedKey].steps).length - 1
        ) {
          const nextStepKey = Object.keys(displayedAccordion[this.currentExpandedKey].steps)[
            currentStepIndex + 1
          ]

          // Verify if last step is selected
          if (!displayedAccordion[this.currentExpandedKey].steps[nextStepKey].selected) {
            this.nextAccordionKey = null
            this.nextStepKey = null
          } else {
            this.nextAccordionKey = this.currentExpandedKey
            this.nextStepKey = nextStepKey
          }
        } else if (currentAccordionIndex < accordionKeys.length - 1) {
          // If the current step is the last step and the current accordion is the last accordion,
          // set nextAccordionKey to the key of the next accordion
          const nextAccordionKey = accordionKeys[currentAccordionIndex + 1]
          this.nextAccordionKey = nextAccordionKey
          this.nextStepKey = Object.keys(displayedAccordion[nextAccordionKey].steps)[0]
        } else {
          // if the current Step is the last step and the current accordion is the last accordion,
          // set nextAccordionKey and nextStepKey null
          this.nextAccordionKey = null
          this.nextStepKey = null
        }
      } else {
      }
    },
    async goBack() {
      const displayedAccordion = this.getDisplayedAccordion()
      if (this.previousAccordionKey !== null && this.previousStepKey !== null) {
        this.nextAccordionKey = this.currentExpandedKey
        this.nextStepKey = this.currentStepKey
        if (
          displayedAccordion[this.previousAccordionKey].steps[this.previousStepKey].proposals
            ?.length === 1
        ) {
          // find the previous step with more than one proposal
          const previousSteps = Object.keys(displayedAccordion[this.previousAccordionKey].steps)
          const currentIndex = previousSteps.indexOf(this.previousStepKey)
          for (let i = currentIndex - 1; i >= 0; i--) {
            if (
              displayedAccordion[this.previousAccordionKey].steps[previousSteps[i]].proposals
                .length > 1
            ) {
              this.previousStepKey = previousSteps[i]
              break
            }
          }
          // If not previous step with one proposal is found, got to previous accordion
          if (this.previousStepKey === this.currentStepKey) {
            const previousAccordionKey = accordionKeys[currentAccordionIndex - 1]
            this.previousAccordionKey = previousAccordionKey
            this.previousStepKey = Object.keys(displayedAccordion[previousAccordionKey].steps)[
              Object.keys(displayedAccordion[previousAccordionKey].steps).length - 1
            ]
          }
        }
        this.currentExpandedKey = this.previousAccordionKey
        this.currentStepKey = this.previousStepKey
        await nextTick()
        // console.log(displayedAccordion)

        // Find the index of the current step
        const currentStepIndex = Object.keys(
          displayedAccordion[this.currentExpandedKey].steps
        ).indexOf(this.currentStepKey)

        // Find the index of the current accordion
        const accordionKeys = Object.keys(displayedAccordion)
        const currentAccordionIndex = accordionKeys.indexOf(this.currentExpandedKey)

        // If the current step is not the first step, update previousAccordionKey and previousStepKey
        if (currentStepIndex > 0) {
          const previousStepKey = Object.keys(displayedAccordion[this.currentExpandedKey].steps)[
            currentStepIndex - 1
          ]

          this.previousAccordionKey = this.currentExpandedKey
          this.previousStepKey = previousStepKey
        } else if (currentAccordionIndex > 0) {
          // If the current step is the first step and the current accordion is not the first accordion,
          // set previousAccordionKey to the key of the previous accordion
          const previousAccordionKey = accordionKeys[currentAccordionIndex - 1]
          this.previousAccordionKey = previousAccordionKey
          this.previousStepKey = Object.keys(displayedAccordion[previousAccordionKey].steps)[
            Object.keys(displayedAccordion[previousAccordionKey].steps).length - 1
          ]
        } else {
          // If the current step is the first step and the current accordion is the first accordion,
          // set previousAccordionKey and previousStepKey to null
          this.previousAccordionKey = null
          this.previousStepKey = null
        }
      } else {
        // Handle the case where the user is on the first step
        // console.log('You are already on the first step')
      }
    },
    getImageUrl(option) {
      const iconFileNameKey = option.k
      const mappedValue = this.brandMappings[iconFileNameKey]
      const iconFileName = mappedValue ? mappedValue.toLowerCase() : option.v.toLowerCase()
      return `https://www.brands.audaris.eu/web/img/brands/190x190/${iconFileName}.png`
    },

    findExpandedIndex(currentStepKey) {
      const displayedAccordion = this.getDisplayedAccordion()
      Object.keys(displayedAccordion).forEach((accordionKey) => {
        const accordion = displayedAccordion[accordionKey]
        Object.keys(accordion.steps).forEach((stepKey) => {
          if (stepKey === currentStepKey) {
            this.currentExpandedKey = accordionKey
          }
        })
      })
      return this.currentExpandedKey
    },
    defineStepKey() {
      const displayedAccordion = this.getDisplayedAccordion()
      this.nextStep = Object.values(displayedAccordion).find((accordion) =>
        Object.values(accordion.steps).find((step) => !step.selected)
      )
      // console.log(displayedAccordion)
      if (this.nextStep) {
        const stepKeys = Object.keys(this.nextStep.steps)
        for (const stepKey of stepKeys) {
          if (!this.nextStep.steps[stepKey].selected) {
            this.currentStepKey = stepKey
            this.currentExpandedKey = this.findExpandedIndex(this.currentStepKey)
            // console.log(this.currentStepKey)
            // console.log(this.currentExpandedKey)
            return stepKey
          }
        }
      }
      return null
    },
    updateEquipmentMatrix(event, option) {
      const accordion = this.getDisplayedAccordion()[this.currentExpandedKey]
      const step = accordion.steps[this.currentStepKey]

      const equipmentOption = step.proposals.find((item) => item.av === option.av)

      if (equipmentOption) {
        if (event.target.checked) {
          step.selectedProposal = {
            ...step.selectedProposal,
            [equipmentOption.av]: {
              av: equipmentOption.av,
              name: equipmentOption.name
            }
          }
          // console.log(accordion)
        } else {
          const { [equipmentOption.av]: value, ...rest } = step.selectedProposal
          step.selectedProposal = rest
          if (Object.keys(step.selectedProposal).length === 0) {
            step.selected = false
          }
        }
      }
    },
    selectFilterProposal(option, stepKey, accordionKey) {
      try {
        // console.log('selectFilterProposal called with :', stepKey, accordionKey)
        // console.log('Selecting new option:', option)

        const displayedAccordion = this.getDisplayedAccordion()
        const accordion = displayedAccordion[accordionKey]
        // console.log('Current state of the store:')
        // console.log(accordion)
        this.previousStepKey = stepKey
        this.previousAccordionKey = accordionKey
        // console.log(this.previousStepKey)
        // console.log(this.previousAccordionKey)
        const step = accordion.steps[stepKey]
        if (step.selected) {
          this.currentStepKey = stepKey
          this.currentExpandedKey = accordionKey

          this.previousAccordionKey = accordionKey
          // Reset steps in current accordion that come after the current step
          const stepKeys = Object.keys(accordion.steps)
          for (let i = stepKeys.indexOf(stepKey) + 1; i < stepKeys.length; i++) {
            const key = stepKeys[i]
            accordion.steps[key].selectedProposal = []
            accordion.steps[key].selected = false
            accordion.steps[key].visited = false
          }

          // Reset accordions that come after the current accordion

          const accordionKeys = Object.keys(displayedAccordion)
          for (let i = accordionKeys.indexOf(accordionKey) + 1; i < accordionKeys.length; i++) {
            const key = accordionKeys[i]
            const innerAccordion = displayedAccordion[key]
            for (const stepKey in innerAccordion.steps) {
              innerAccordion.steps[stepKey].selectedProposal = {}
              innerAccordion.steps[stepKey].selected = false
            }
          }
        }
        if (stepKey === 'constructionYear') {
          const generatedInitialRegistration = `${option.k}-01-01`
          this.accordion.accordion4.steps.carRegistration.selectedProposal = {
            initialRegistration: generatedInitialRegistration
          }
        }
        step.selectedProposal = option
        step.selected = true
        this.fetchTradeInData()
        // console.log('Updated state of the store:')
        // console.log(accordion)
      } catch (error) {}
    },

    updateProposals(stepKey, value) {
      if (!this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selectedProposal) {
        this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selectedProposal = {}
      }
      this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selectedProposal[stepKey] =
        value
    },
    async nextSelection() {
      // Save the current step and accordion keys as previous keys
      this.previousStepKey = this.currentStepKey
      this.previousAccordionKey = this.currentExpandedKey

      const displayedAccordion = this.getDisplayedAccordion()
      const accordion = displayedAccordion[this.currentExpandedKey]
      accordion.steps[this.currentStepKey].selected = true

      this.defineStepKey()
      this.goForward()

      // console.log(displayedAccordion)
    },
    displaySelected(content) {
      const accordion = this.getDisplayedAccordion()
      const isHersteller = this.activeTab === 'hersteller'

      switch (content) {
        case 'vin':
          if (this.selectedVehicle && this.selectedVehicle.containerName) {
            return `Fahrzeug identifiziert: ${this.selectedVehicle.containerName}`
          } else {
            return 'Fahrzeugsuche über Fahzeugidentifikationsnummer'
          }

        case 'hsnTsn':
          if (this.selectedVehicle && this.selectedVehicle.containerName) {
            return `Fahrzeug identifiziert: ${this.selectedVehicle.containerName}`
          } else {
            return 'Fahrzeugsuche über HSN/TSN'
          }

        case 'manufacturer':
          const manufacturerDisplays = Object.values(accordion.accordion1.steps)
            .map((step) => {
              if (step.selectedProposal && step.selectedProposal.k && step.selectedProposal.v) {
                return step.selectedProposal.v
              }
            })
            .filter(Boolean)
          return manufacturerDisplays.length > 0
            ? manufacturerDisplays.join(', ')
            : accordion.accordion1.title

        case 'vehicleDetail':
          const vehicleDisplays = Object.values(accordion.accordion2.steps)
            .map((step) => {
              if (step.selectedProposal && step.selectedProposal.k && step.selectedProposal.v) {
                return step.selectedProposal.v
              }
            })
            .filter(Boolean)
          return vehicleDisplays.length > 0
            ? vehicleDisplays.join(', ')
            : accordion.accordion2.title
        case 'equipment':
          const equipmentAccordion = isHersteller ? accordion.accordion3 : accordion.accordion2
          return Object.keys(equipmentAccordion.steps.specialEquipments.selectedProposal || {})
            .length
            ? `${Object.keys(equipmentAccordion.steps.specialEquipments.selectedProposal || {}).length} Ausgewählt`
            : '0 Ausgewählt'
        case 'carRegistration':
          const carRegistrationAccordion = isHersteller
            ? accordion.accordion4
            : accordion.accordion3
          const carRegistration = carRegistrationAccordion.steps.carRegistration
          if (carRegistration.selectedProposal) {
            const { initialRegistration, milage } = carRegistration.selectedProposal
            if (initialRegistration && milage) {
              const formattedErstzulassung = `${initialRegistration.split('-')[2]}. ${this.months[initialRegistration.split('-')[1]]} ${initialRegistration.split('-')[0]}`
              return `Erstzulassung: ${formattedErstzulassung}, Kilometerstand: ${milage}`
            } else if (initialRegistration) {
              const formattedErstzulassung = `${initialRegistration.split('-')[2]}. ${this.months[initialRegistration.split('-')[1]]} ${initialRegistration.split('-')[0]}`

              return `Erstzulassung: ${formattedErstzulassung}`
            } else if (milage) {
              return `Kilometerstand: ${milage}`
            }
          }
          return 'Erstzulassung, Kilometerstand'
        case 'damage':
          const damageAccordion = isHersteller ? accordion.accordion5 : accordion.accordion4
          const accident = damageAccordion.steps.accident.selectedProposal.accident
          const damage = damageAccordion.steps.damage.selectedProposal.damage
          if (accident && damage) {
            return 'Unfallfahrzeug, Beschädigt'
          } else if (accident && !damage) {
            return 'Unfallfahrzeug, ohne Beschädigungen'
          } else if (!accident && damage) {
            return 'Unfallfrei, beschädigt'
          } else {
            return 'Unfallfrei, ohne Beschädigungen'
          }
        case 'priceRequest':
          const priceRequestAccordion = isHersteller ? accordion.accordion6 : accordion.accordion5
          return `${!priceRequestAccordion.steps.price.selectedProposal.saleType || priceRequestAccordion.steps.price.selectedProposal.saleType === 'PRIVATE' ? 'Verkauf von Privat' : 'gewerblicher Verkauf'}, ${!priceRequestAccordion.steps.price.selectedProposal.priceRequest ? 'Preisvorschlag' : `Preisvorschlag: ${priceRequestAccordion.steps.price.selectedProposal.priceRequest} €`}`

        case 'submit':
          const submitAccordion = isHersteller ? accordion.accordion7 : accordion.accordion6
          return 'Sie erhalten in nur wenigen Minuten Ihr individuelles Angebot'
        default:
          return ''
      }
    }
  }
})
