<template>
  <div class="flex flex-col items-center text-center p-8">
    <BIconCheck2Circle style="font-size: 300px; fill: #00ff00" />
    <p class="text-xl mt-8">
      Die Anfrage wurde erfolgreich an uns gesendet. Wir werden uns in wenigen Minuten mit unserem
      Angebot an Sie bei Ihnen melden.<br />Unser vorläufiges Angebot beträgt
      <span class="text-brand font-semibold">{{ purchasePrice }}€</span>
    </p>
  </div>
</template>

<script setup>
import { BIconCheck2Circle } from 'bootstrap-icons-vue'
import { useRequestStore } from '../../requestStore.js'
const requestStore = useRequestStore()

const purchasePrice = requestStore.responseData.purchasePriceGrossRounded
</script>
