<template>
  <div class="text-center p-8 flex-col">
    <BIconExclamationOctagon style="font-size: 6rem; fill: red" class="mx-auto" />
    <p class="text-lg mt-8">
      Leider ist beim Senden Ihrer Anfrage ein Fehler aufgetreten. Sie können es gerne hier noch
      einmal probieren:
    </p>
    <p>{{ requestStore.requestError }}</p>
    <div class="flex justify-around my-14">
      <button
        @click="requestStore.requestError = null"
        class="flex items-center border rounded shadow py-1 bg-brand text-white font-semibold px-4 shadow-md hover:bg-brand-darker disabled:opacity-50 disabled:pointer-events-none"
      >
        Zurück zum Ankaufformular</button
      ><button
        @click="requestStore.fetchRequestData()"
        class="flex items-center border rounded shadow py-1 bg-brand text-white font-semibold px-4 shadow-md hover:bg-brand-darker disabled:opacity-50 disabled:pointer-events-none"
      >
        Noch einmal probieren
      </button>
    </div>
  </div>
</template>

<script setup>
import { BIconExclamationOctagon } from 'bootstrap-icons-vue'
import { useRequestStore } from '../../requestStore.js'

const requestStore = useRequestStore()
</script>
