<template>
  <div class="popup-overlay" @click.self="closePopup">
    <div class="popup-content">
      <div class="flex justify-content-center mb-4 mt-4">
        <h2 class="mx-auto text-2xl pt-2">Kostenlose Online-Bewertung</h2>
        <button
          class="flex items-center justify-center w-10 h-10 border-2 border-gray-400 m-2 rounded-full text-gray-400 text-2xl focus:outline-none hover:text-gray-600 hover:border-gray-600"
          @click="closePopup"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
            />
          </svg>
        </button>
      </div>
      <slot />
      <p class="mt-4 mb-2 mx-4 text-right text-xs text-gray-600">
        Ein Service von
        <a class="underline text-brand" href="https://audaris.de" target="_blank">audaris GmbH</a> |
        <a class="underline text-brand" href="//www.nic-online.de/datenschutz" target="_blank"
          >Datenschutz</a
        >
      </p>
    </div>
  </div>
</template>

<script setup>
import { useTradeInStore } from '../../tradeInStore.js'
const tradeInStore = useTradeInStore()

const closePopup = () => {
  tradeInStore.closeTradeInWidget()
}
</script>
