import { createRouter, createWebHistory } from 'vue-router'
import TradeInWidgetButton from './components/tradein-widget-button.ce.vue'
import TradingWidget from './components/tradein-widget.ce.vue'

const routes = [
  {
    path: '/:clientId',
    components: {
      button: TradeInWidgetButton,
      widget: TradingWidget
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
