import { createApp, h, useAttrs } from 'vue'
import { createPinia } from 'pinia'
import { defineCustomElement } from 'vue'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue'
import tradeInWidget from './components/tradein-widget.ce.vue'
import TradeinWidgetButton from './components/tradein-widget-button.ce.vue'
import indexCss from './index.css?inline'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import router from './router'

library.add(faHouse)
const app = createApp({})
app.component('FontAwesomeIcon', FontAwesomeIcon)
const pinia = createPinia()

app.use(BootstrapIconsPlugin)
app.use(pinia)
app.use(router)

const TradeInWidgetElement = defineCustomElement({
  setup() {
    const attrs = useAttrs()
    const clientId = attrs.clientId
    const layout = attrs.layout
    const apiEndpoint = `https://tradein.cloud.audaris.icu/-/api`

    async function initialFetch() {
      if (!clientId) return
      const url = new URL(apiEndpoint)
      url.searchParams.set('clientId', clientId)
      try {
        const response = await fetch(url)
        if (!response.ok) {
          const error = new Error(`Error ${response.status}`)
          error.statusCode = response.status
          error.url = url.href // add the URL to the error object
          throw error
        }
        const data = await response.json()
        const settings = data.settings
        const colors = settings.colors
        document.documentElement.style.setProperty('--color-brand', colors.brand)
        document.documentElement.style.setProperty('--color-brand-darker', colors['brand-darker'])
      } catch (error) {
        console.error(error)
      }
    }
    initialFetch()
  },
  render() {
    return h(tradeInWidget)
  },
  mounted() {
    const styleElement = document.createElement('style')
    const layoutElement = document.createElement('layout')
    styleElement.textContent = indexCss.toString()
    this.$el.appendChild(styleElement)
    this.$el.appendChild(layoutElement)
  }
})
const TradeInWidgetButtonElement = defineCustomElement(TradeinWidgetButton)

customElements.define('tradein-widget', TradeInWidgetElement)

customElements.define('tradein-widget-button', TradeInWidgetButtonElement)
