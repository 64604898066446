<template class="manufacturer-content">
  <div class="flex mb-4 justify-between items-center">
    <label>
      <span class="text-sm font-semibold">{{ currentStepPath.title }} suchen</span>
      <input type="text" name="" id="" class="form-input w-full mt-1" v-model="searchText" />
    </label>
    <div class="ml-auto mt-4 flex flex-row gap-x-2">
      <button
        @click="tradeInStore.goBack()"
        class="flex items-center border rounded shadow px-2 py-1 bg-white hover:bg-gray-200"
      >
        <BIconArrowLeft />
      </button>

      <button
        @click="tradeInStore.goForward()"
        class="flex items-center border rounded shadow px-2 py-1 bg-white hover:bg-gray-200"
      >
        <BIconArrowRight />
      </button>
    </div>
  </div>
  <div class="text-sm font-semibold text-audaris-red" v-if="tradeInStore.vehicleSelectionError">
    {{ tradeInStore.vehicleSelectionError }}
  </div>
  <div v-if="tradeInStore.currentStepKey === 'manufacturer'" class="opacity-0 animate-fade-in">
    <ul class="grid grid-cols-[repeat(auto-fill,_minmax(95px,_1fr))] gap-2">
      <li
        v-for="(option, index) in filteredProposals"
        :key="index"
        :class="[
          'h-full flex flex-col items-center px-1 py-1 justify-center shadow text-xs border rounded border-gray-300 cursor-pointer ',
          currentStepPath.selectedProposal === option
            ? 'bg-brand text-white'
            : 'bg-white hover:bg-gray-200',
          index >= 12 && !showMore ? 'hidden' : ''
        ]"
        @click="
          tradeInStore.selectFilterProposal(
            option,
            tradeInStore.currentStepKey,
            tradeInStore.currentExpandedKey
          )
        "
      >
        <img :src="tradeInStore.getImageUrl(option)" :alt="option.v" class="h-16 w-16" />
        <input
          type="radio"
          :id="`${currentStepPath.proposals}-${index}`"
          :value="option"
          class="hidden"
        />
        {{ option.v }}
      </li>
    </ul>
    <button
      @click="showMore = !showMore"
      class="mt-2 text-sm flex items-center px-2 py-1 text-brand font-semibold hover:underline disable:opacity-50"
    >
      <span :class="{ 'rotate-180': !showMore }">
        <BIconChevronUp style="font-size: 16px" />
      </span>
      {{ showMore ? 'Weniger anzeigen' : 'Alle anzeigen' }}
    </button>
  </div>
  <div v-else-if="currentStepPath" class="flex flex-wrap gap-x-2 gap-y-2 opacity-0 animate-fade-in">
    <label
      v-for="(option, index) in filteredProposals"
      :key="`${currentStepPath}-${index}`"
      :class="[
        'flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer',
        currentStepPath.selectedProposal === option
          ? 'bg-brand text-white'
          : tradeInStore.vehicleSelectionError
            ? 'text-audaris-red'
            : 'bg-white hover:bg-gray-200'
      ]"
      @click.self="
        tradeInStore.selectFilterProposal(
          option,
          tradeInStore.currentStepKey,
          tradeInStore.currentExpandedKey
        )
      "
    >
      <input type="radio" :id="`${currentStepPath}-${index}`" :value="option" class="hidden" />
      {{ option.v }}
    </label>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useTradeInStore } from '../../tradeInStore.js'
import { BIconArrowLeft, BIconArrowRight, BIconChevronUp } from 'bootstrap-icons-vue'

const tradeInStore = useTradeInStore()

let showMore = ref(false)
let searchText = ref('')

let currentStepPath = computed(() => {
  return tradeInStore.accordion[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
})

const filteredProposals = computed(() => {
  if (!currentStepPath.value || !currentStepPath.value.proposals || !searchText.value) {
    return currentStepPath.value.proposals
  }

  return currentStepPath.value.proposals.filter((proposal) =>
    proposal.v.toLowerCase().includes(searchText.value.toLowerCase())
  )
})
</script>

<style scoped>
@import '../../index.css';
</style>
