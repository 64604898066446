<script setup>
import { onMounted, useAttrs } from 'vue'
import { useTradeInStore } from '../tradeInStore.js'
import tradeInWidget from './tradein-widget.ce.vue'
import Popup from './utils/popup.vue'

const props = defineProps({
  layout: {
    type: String,
    default: 'default'
  }
})

const tradeInStore = useTradeInStore()

const attrs = useAttrs()
tradeInStore.setAttributesFromProps(attrs)
</script>

<template>
  <div v-if="tradeInStore.error">
    <div class="bg-red-200 p-4 text-red-800 antialiased">
      <div>
        <h1 class="font-bold text-4xl">Systemfehler</h1>
        <pre class="mt-4 text-sm leading-none"
          >{{ tradeInStore.error.message }}{{ tradeInStore.error.detail }}</pre
        >
      </div>
    </div>
  </div>
  <div v-else>
    <button
      @click="tradeInStore.openTradeInWidget()"
      :class="[
        layout === 'unstyled'
          ? []
          : [
              'bg-brand',
              'hover:bg-brand-darker',
              'text-white',
              'font-bold',
              'py-2',
              'px-4',
              'rounded'
            ]
      ]"
      :style="[attrs.style]"
    >
      <slot>Inzahlungname?</slot>
    </button>
    <Popup v-if="tradeInStore.showTradeInWidget">
      <div>
        <tradeInWidget :clientId="tradeInStore.clientId" />
      </div>
    </Popup>
  </div>
</template>

<style>
@import '../index.css';
</style>
