<template>
  <div v-if="tradeInStore.currentStepKey === 'accident'" class="flex flex-wrap gap-x-2 gap-y-2">
    <label
      for="unfallfrei"
      :class="{
        'bg-white hover:bg-gray-200': currentStepPath.accident,
        'bg-brand text-white': !currentStepPath.accident
      }"
      class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
    >
      <input
        type="radio"
        id="unfallfrei"
        v-model="currentStepPath.accident"
        :value="false"
        class="hidden"
      />
      Unfallfrei
    </label>
    <label
      for="accident"
      :class="{
        'bg-white hover:bg-gray-200': !currentStepPath.accident,
        'bg-brand text-white': currentStepPath.accident
      }"
      class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
    >
      <input
        type="radio"
        id="accident"
        v-model="currentStepPath.accident"
        :value="true"
        class="hidden"
      />
      Fahrzeug hatte einen Unfall
    </label>

    <div v-show="currentStepPath.accident" class="flex flex-wrap gap-x-2 gap-y-2 w-full">
      <label for="accident-text" class="w-full">
        Bitte beschreiben Sie den Unfall:
        <textarea
          id="accident-text"
          v-model="currentStepPath.accidentDescription"
          class="form-input w-full mt-1"
        ></textarea>
      </label>
    </div>
  </div>

  <div v-else-if="tradeInStore.currentStepKey === 'damage'" class="flex flex-wrap gap-x-2 gap-y-2">
    <template v-if="currentStepPath">
      <label
        for="notDamaged"
        :class="{
          'bg-white hover:bg-gray-200': currentStepPath[tradeInStore.currentStepKey],
          'bg-brand text-white': !currentStepPath[tradeInStore.currentStepKey]
        }"
        class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
      >
        <input
          type="radio"
          id="notDamaged"
          v-model="currentStepPath[tradeInStore.currentStepKey]"
          :value="false"
          class="hidden"
        />
        Keine Beschädigungen
      </label>
      <label
        for="damaged"
        :class="{
          'bg-white hover:bg-gray-200': !currentStepPath[tradeInStore.currentStepKey],
          'bg-brand text-white': currentStepPath[tradeInStore.currentStepKey]
        }"
        class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
      >
        <input
          type="radio"
          id="damaged"
          v-model="currentStepPath[tradeInStore.currentStepKey]"
          :value="true"
          class="hidden"
        />
        Fahrzeug ist Beschädigt
      </label>

      <div
        v-show="currentStepPath[tradeInStore.currentStepKey]"
        class="flex flex-wrap gap-x-2 gap-y-2 w-full"
      >
        <label for="damage-text" class="w-full">
          Bitte beschreiben Sie die Beschädigungen:
          <textarea
            type="text"
            id="damage-text"
            v-model="currentStepPath.damageDescription"
            class="form-input w-full mt-1"
          ></textarea>
        </label>
      </div>
    </template>
  </div>
  <div class="flex justify-end py-4">
    <button
      @click="tradeInStore.goBack()"
      class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
    >
      <BIconArrowLeft />Zurück
    </button>

    <button
      @click="tradeInStore.nextSelection()"
      :disabled="
        (currentStepPath.accident && !currentStepPath.accidentDescription) ||
        (currentStepPath.damage && !currentStepPath.damageDescription)
      "
      :class="[
        (currentStepPath.accident && !currentStepPath.accidentDescription) ||
        (currentStepPath.damage && !currentStepPath.damageDescription)
          ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
          : 'bg-brand text-white font-semibold shadow-md hover:bg-brand-darker'
      ]"
      class="ml-4 flex items-center border rounded shadow px-2 py-1"
    >
      Weiter
    </button>
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import { useTradeInStore } from '../../tradeInStore.js'
import { BIconArrowLeft } from 'bootstrap-icons-vue'

const tradeInStore = useTradeInStore()

const displayedAccordion = computed(() => {
  return tradeInStore.activeTab === 'hersteller'
    ? tradeInStore.accordion
    : tradeInStore.hsnTsnaccordion
})

let currentStepPath = computed(() => {
  return displayedAccordion.value[tradeInStore.currentExpandedKey].steps[
    tradeInStore.currentStepKey
  ].selectedProposal
})
watch(
  () => displayedAccordion.value[tradeInStore.currentExpandedKey].steps.accident.selectedProposal,
  (newValue) => {
    if (!newValue.accident) {
      newValue.accidentDescription = ''
    }
  },
  { deep: true }
)

watch(
  () => displayedAccordion.value[tradeInStore.currentExpandedKey].steps.damage.selectedProposal,
  (newValue) => {
    if (!newValue.damage) {
      newValue.damageDescription = ''
    }
  },
  { deep: true }
)
onMounted(async () => {
  displayedAccordion.value[tradeInStore.currentExpandedKey].steps[
    tradeInStore.currentStepKey
  ].visited = true
})
</script>

<style scoped>
@import '../../index.css';
</style>
