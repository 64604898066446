<template>
  <div class="px-4 md:px-8 py-4 animate-fade-in">
    <form @submit.prevent="submitHsnTsn">
      <div class="flex space-x-4">
        <label class="block flex-1" id="input-hsn"
          ><span class="text-sm font-semibold">HSN</span
          ><input
            type="text"
            class="form-input w-full mt-1"
            maxlength="4"
            v-model="currentStepPath.selectedProposal.hsn" /></label
        ><label class="block flex-1" id="input-tsn"
          ><span class="text-sm font-semibold">TSN</span
          ><input
            type="text"
            class="form-input w-full mt-1"
            v-model="currentStepPath.selectedProposal.tsn"
        /></label>
      </div>
      <div class="text-sm font-semibold text-audaris-red" v-if="tradeInStore.hsnTsnError">
        {{ tradeInStore.hsnTsnError }}
      </div>
      <div v-else class="my-2">
        <button
          type="button"
          @click="isCollapsibleOpen = !isCollapsibleOpen"
          class="flex items-center gap-2 font-semibold text-gray-600 hover:underline"
        >
          <BIconChevronDoubleRight class="text-xl text-brand" />
          Was sind HSN und TSN?
        </button>
        <div
          v-show="isCollapsibleOpen"
          id="collapsible-hsn-tsn"
          class="overflow-hidden animate-fade-in"
        >
          <div class="py-4 flex flex-wrap gap-2 items-start">
            <div class="grow basis-72 mb-4">
              <p>
                Die Abkürzung HSN steht für Herstellerschlüsselnummer und ist eine Bezeichnung für
                den Hersteller eines Kraftfahrzeugs oder Anhängers. TSN bedeutet Typschlüsselnummer
                und umschreibt den Fahrzeugtyp eines Herstellers.<br />Die Kombination von HSN und
                TSN wird häufig zusammenfassend auch als Schlüsselnummer, oder alternativ
                KBA-Nummer, bezeichnet.<br />Die Schlüsselnummer (bzw. KBA-Nummer) Ihres Autos
                finden Sie in der Zulassungsbescheinigung Teil 1, bzw. bei älteren Autos im
                Fahrzeugschein:
              </p>
              <ul class="mt-2 list-disc">
                <li class="ml-1">• Die HSN ist in Feld 2.1 angegeben,</li>
                <li class="ml-1">• die TSN steht im Feld 2.2.</li>
              </ul>
              <p class="mt-2 text-xs italic">Quelle: ADAC</p>
            </div>
            <img
              src="@/assets/hsn-tsn.jpg"
              class="grow-max basis-0 min-w-2/3 w-full object-contain"
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        class="flex items-center border rounded shadow px-2 py-1 bg-brand text-white font-semibold text-xl px-4 shadow-md hover:bg-brand-darker disabled:opacity-50 disabled:pointer-events-none"
      >
        Suchen
      </button>
    </form>
  </div>
</template>

<script setup>
import { useTradeInStore } from '../../tradeInStore'
import { computed, onMounted, ref } from 'vue'
import { BIconChevronDoubleRight } from 'bootstrap-icons-vue'

const tradeInStore = useTradeInStore()

const isCollapsibleOpen = ref(false)

const displayedAccordion = computed(() => {
  return tradeInStore.activeTab === 'hersteller'
    ? tradeInStore.accordion
    : tradeInStore.activeTab === 'vin'
      ? tradeInStore.vinAccordion
      : tradeInStore.hsnTsnaccordion
})
const currentStepPath = computed(() => {
  return displayedAccordion.value[tradeInStore.currentExpandedKey].steps[
    tradeInStore.currentStepKey
  ]
})

const submitHsnTsn = () => {
  const option =
    displayedAccordion.value[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
      .selectedProposal

  tradeInStore.selectFilterProposal(option, 'hsnTsn', 'accordion1')
}
onMounted(async () => {
  let currentStep =
    displayedAccordion.value[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
  if (!currentStep.selectedProposal) {
    currentStep.selectedProposal = {
      hsn: '',
      tsn: ''
    }
  }
  currentStep.visited = true
})
</script>

<style scoped>
@import '../../index.css';

.collapsible {
  display: none;
  transition: all 0.3s ease-in-out;
}

.collapsible.open {
  display: block;
}
</style>
