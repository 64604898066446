<template>
  <div v-if="currentStepPath" class="grid md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2 gap-4">
    <label class="">
      <span class="text-sm font-semibold">Erstzulassung</span>
      <input
        type="date"
        class="form-input w-full mt-1"
        v-model="currentStepPath.selectedProposal.initialRegistration"
      />
    </label>
    <label class="">
      <span class="text-sm font-semibold">Kilometerstand</span>
      <input
        type="number"
        class="form-input w-full mt-1"
        min="500"
        v-model="currentStepPath.selectedProposal.milage"
      />
    </label>
    <label class="">
      <span class="text-sm font-semibold whitespace-nowrap">Anzahl Vorbesitzer</span>
      <input
        type="number"
        class="form-input w-full mt-1"
        min="0"
        v-model="currentStepPath.selectedProposal.numberOfPreviousOwner"
      />
    </label>
    <label class="">
      <span class="text-sm font-semibold">Farbe</span>
      <select class="form-input w-full mt-1" v-model="currentStepPath.selectedProposal.color">
        <option value="">Bitte wählen</option>
        <option value="Weiß">Weiß</option>
        <option value="Schwarz">Schwarz</option>
        <option value="Silber">Silber</option>
        <option value="Orange">Orange</option>
        <option value="Gelb">Gelb</option>
        <option value="Lila">Lila</option>
        <option value="Gold">Gold</option>
        <option value="Blau">Blau</option>
        <option value="Grün">Grün</option>
        <option value="Grau">Grau</option>
        <option value="Rot">Rot</option>
        <option value="Beige">Beige</option>
        <option value="Anthrazit">Anthrazit</option>
      </select>
    </label>
  </div>
  <div class="flex justify-end py-4">
    <button
      @click="tradeInStore.goBack()"
      class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
    >
      <BIconArrowLeft />Zurück
    </button>
    <button
      @click="tradeInStore.nextSelection()"
      class="ml-4 flex items-center border rounded shadow px-2 py-1 bg-brand text-white font-semibold shadow-md hover:bg-brand-darker"
    >
      Weiter
    </button>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useTradeInStore } from '../../tradeInStore.js'
import { BIconArrowLeft } from 'bootstrap-icons-vue'
const tradeInStore = useTradeInStore()

const displayedAccordion = computed(() => {
  return tradeInStore.activeTab === 'hersteller'
    ? tradeInStore.accordion
    : tradeInStore.activeTab === 'vin'
      ? tradeInStore.vinAccordion
      : tradeInStore.hsnTsnaccordion
})

const currentStepPath = computed(() => {
  return displayedAccordion.value[tradeInStore.currentExpandedKey].steps[
    tradeInStore.currentStepKey
  ]
})
onMounted(async () => {
  let currentStep =
    displayedAccordion.value[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
  if (!currentStep.selectedProposal) {
    currentStep.selectedProposal = {}
  }
  if (!currentStep.selectedProposal.milage) {
    currentStep.selectedProposal.milage = 10000
  }
  if (!currentStep.selectedProposal.numberOfPreviousOwner) {
    currentStep.selectedProposal.numberOfPreviousOwner = 0
  }
  if (!currentStep.selectedProposal.color) {
    currentStep.selectedProposal.color = ''
  }
  currentStep.visited = true
})
</script>

<style scoped>
@import '../../index.css';
</style>
